
import {defineComponent} from 'vue';
import DetailLayout from '@/layouts/DetailLayout.vue';

export default defineComponent({
  name: 'ProjectDetail',
  components: {DetailLayout},
  setup(props, {emit}) {
    return {};
  },
});
